<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      item-key="id"
      sort-by="marca"
      :search="search"
      mobile-breakpoint="0"
      calculate-widths
      class="elevation-4"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
    >
      <template #top>
        <v-toolbar rounded flat>
          <h3>Traslados Enviados</h3>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-row class="pa-2">
          <v-col cols="12" md="3">
            <v-text-field
              v-model="dateFrom"
              label="Desde"
              hide-details="auto"
              required
              outlined
              type="date"
              :rules="f_required"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="dateTo"
              label="Hasta"
              hide-details="auto"
              required
              outlined
              type="date"
              :rules="f_required"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-btn x-large color="success" ref="submitbtn" @click="get_report"
              >Buscar</v-btn
            >
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.costo`]="{ item }">
        <span>{{ parseFloat(item.costo).toLocaleString(2) }}</span>
      </template>
      <template v-slot:[`item.tcosto`]="{ item }">
        <span>{{
          "$" + (parseFloat(item.costo) * parseFloat(item.quantity)).toLocaleString(2)
        }}</span>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <span>{{ "$" + parseFloat(item.price).toLocaleString(2) }}</span>
      </template>
      <template v-slot:[`item.tprecio`]="{ item }">
        <span>{{
          "$" + (parseFloat(item.price) * parseFloat(item.quantity)).toLocaleString(2)
        }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="get_order(item)"> mdi-magnify </v-icon>
      </template>

      <template slot="body.append">
        <tr>
          <th>Total</th>
          <th></th>
          <th class="text-right">{{ sumTable("items", "ordered_cost") }}</th>
          <th class="text-right">{{ sumTable("items", "ordered_val") }}</th>
          <th class="text-right">{{ sumTable("items", "ordered_qty") }}</th>
          <th class="text-right">{{ sumTable("items", "delivered_qty") }}</th>
          <th class="text-right">{{ sumTable("items", "difference_qty") }}</th>
          <th class="text-right">{{ "$ " + sumTable("items", "difference_val") }}</th>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="order_dialog" scrollable max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            Productos a ingresar
            <v-spacer></v-spacer>
            <v-icon @click="order_dialog = !order_dialog"> mdi-close </v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-3">
          <v-data-table
            v-model="selected"
            :headers="header_order"
            :items="orderItems"
            item-key="product_code"
            loading-text="Cargando ..."
            show-select
            disable-pagination
            calculate-widths
            hide-default-footer
          >
            <template v-slot:[`item.quantity`]="{ item }">
              <!-- <span>{{ parseFloat(item.quantity).toLocaleString(2) }}</span> -->
              <v-edit-dialog
                :return-value.sync="item['quantity']"
                @save="save"
                save-text="Guardar"
                @cancel="cancel"
                cancel-text="Cancelar"
                @open="open"
                @close="close"
                large
              >
                {{ item["quantity"] }}
                <template v-slot:input>
                  <v-text-field
                    label="Cantidad"
                    v-model="item['quantity']"
                    single-line
                    outlined
                    hide-details
                    class="pt-4"
                    dense
                  />
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.order_cost`]="{ item }">
              <span>{{ "$" + parseFloat(item.order_cost).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span
                v-if="
                  !(
                    parseInt(item.price) - parseInt(item.price_inv) !== 0 &&
                    item.price_inv != 0
                  )
                "
              >
                {{ "$" + parseFloat(item.price).toLocaleString(2) }}
              </span>
              <span v-else>
                {{ "$" + parseFloat(item.price).toLocaleString(2) }}
                <v-icon class="mr-2" color="red"> mdi-close </v-icon>
              </span>
            </template>
            <template slot="body.append">
              <tr>
                <th class="title">Total</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="text-right">{{ sumTable("orderItems", "quantity") }}</th>
                <th class="text-right">
                  {{ "$" + sumTable("orderItems", "order_cost") }}
                </th>
                <th class="text-right">{{ "$" + sumTable("orderItems", "price") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions dark color="secondary">
          <v-spacer></v-spacer>
          <v-form v-model="validIn" ref="formIn" @submit="StockIn">
            <v-row>
              <v-col cols="3" md="3">
                <v-text-field
                  v-model="SelectedCount"
                  align="right"
                  label="Seleccionados"
                  hide-details="auto"
                  outlined
                  readonly
                />
              </v-col>
              <v-col cols="3" md="3">
                <v-select
                  v-model="typeIn"
                  :items="opin"
                  item-value="codigo"
                  item-text="valor"
                  label="Tipo Ingreso"
                  placeholder="Marca"
                  clearable
                  hide-details="auto"
                  outlined
                  :rules="f_required"
                />
              </v-col>
              <v-col cols="3" md="3">
                <v-text-field
                  v-model="docRefIn"
                  label="Referencia"
                  hide-details="auto"
                  required
                  outlined
                  :rules="f_required"
                />
              </v-col>
              <v-col cols="3" md="3">
                <v-btn
                  x-large
                  color="success"
                  block
                  type="submit"
                  :disabled="btnIN"
                  v-if="this.selected.length > 0 && !this.error_form"
                >
                  Ingresar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-actions>
      </v-card>
      <v-snackbar
        v-model="errorOrder"
        :timeout="timeout"
        absolute
        centered
        dark
        color="red"
        elevation="24"
      >
        Existen productos con diferente precio en el inventario.
      </v-snackbar>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";

import createDoc from "../utils/create_doc.js";
export default {
  components: {},
  data() {
    return {
      save() {},
      cancel() {},
      open() {},
      close() {},
      btnIN: false,
      search: "",
      loading_status: false,
      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          filterable: true,
          value: "order_date",
          dataType: "text",
        },
        {
          text: "Origen",
          align: "start",
          sortable: true,
          filterable: true,
          value: "origen",
          dataType: "text",
        },
        {
          text: "Documento",
          align: "start",
          sortable: true,
          filterable: true,
          value: "order_id",
          dataType: "text",
        },

        {
          text: "Dias",
          align: "end",
          sortable: true,
          filterable: true,
          value: "order_age",
          dataType: "text",
        },
        {
          text: "Costo",
          align: "end",
          sortable: true,
          filterable: true,
          value: "ordered_cost",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          filterable: true,
          value: "ordered_val",
          dataType: "text",
        },
        {
          text: "Pedidos",
          align: "end",
          sortable: true,
          filterable: true,
          value: "ordered_qty",
          dataType: "text",
        },
        {
          text: "Entregados",
          align: "end",
          sortable: true,
          filterable: true,
          value: "delivered_qty",
          dataType: "text",
        },
        {
          text: "Diferencia",
          align: "end",
          sortable: true,
          filterable: true,
          value: "difference_qty",
          dataType: "text",
        },
        {
          text: "Valor Diferencia",
          align: "end",
          sortable: true,
          filterable: true,
          value: "difference_val",
          dataType: "text",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      header_order: [
        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "marca",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          value: "color",
          dataType: "text",
        },
        {
          text: "Talla",
          align: "start",
          sortable: true,
          value: "size",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Costo",
          align: "end",
          sortable: true,
          value: "order_cost",
          dataType: "number",
        },

        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "number",
        },
      ],
      items: [],
      dialog: false,
      item: createDoc(),
      dateFrom: getdays_ago(-30),
      dateTo: getToday(),
      chartData: null,
      chartOptions: {
        curveType: "function",
        legend: { position: "right" },
        interpolateNulls: true,
        crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
        title: "",
        isStacked: false,
        hAxis: {
          title: "",
          slantedText: true,
          slantedTextAngle: 15,
        },
        colors: ["#1b9e77", "#d95f02", "#7570b3"],
        height: 400,
      },
      brand: null,
      brands: [],
      f_required: [(v) => !!v || "Requerido"],
      searchvalid: false,
      docId: null,
      docDate: null,
      orderItems: [],
      order_dialog: false,
      selected: [],
      opin: [],
      typeIn: null,
      docRefIn: null,
      validIn: false,
      SelectedCount: 0,
      error_form: false,
      errorOrder: false,
      timeout: 5000,
    };
  },

  mounted() {
    // this.brands = window.settings["MARCA"].sort(this.GetSortOrder("valor"));
    this.opin = window.settings["TB"];
  },
  methods: {
    showDoc(item) {
      console.log(item);
      this.selected = [];
      this.docId = item.order_id;
      this.docDate = item.order_date;
      this.$refs.submitbtn.$el.click();
      this.order_dialog = true;
    },
    sumTable(table, key) {
      return parseFloat(
        this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)
      ).toLocaleString(2);
    },
    get_report() {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        from: this.dateFrom,
        to: this.dateTo,
        order_id: this.docId,
        brand: this.brand,
      };
      this.loading_status = true;
      console.log(qry);
      webserver("get_orders_tb", qry, (data) => {
        console.log(data);

        this.items = data;
        this.docId = null;
        this.loading_status = false;
      });
    },
    get_order(e) {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        from: this.dateFrom,
        to: this.dateTo,
        order_id: e.order_id,
        brand: this.brand,
      };
      this.loading_status = true;
      console.log(qry);
      webserver("get_orders_tb", qry, (data) => {
        this.orderItems = data;
        this.order_dialog = true;
        this.loading_status = false;
      });
    },
    GetSortOrder(prop) {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    },
    StockIn(e) {
      e.preventDefault();
      this.$refs.formIn.validate();
      if (this.validIn) {
        var qry = {};
        (qry.store = window.store.store_id), (qry.issueDate = getToday());
        (qry.user_id = window.profile.user_email),
          (qry.orderRef = this.typeIn + "-" + this.docRefIn);
        qry.data = this.selected;
        console.log(qry);
        this.loading_status = true;
        webserver("order_in", qry, () => {
          this.selected = [];
          this.typeIn = null;
          this.docRefIn = null;
          this.loading_status = false;
          this.order_dialog = false;
          this.get_report();
        });
      }
    },
    get_doc(e) {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        issuedate: e.order_date,
        doc_number: e.order_id,
      };
      console.log(qry);
      webserver("get_doc_b", qry, (data) => {
        this.item = { ...data };
        console.log("get_doc_b", data);
        this.dialog = true;
        this.loading_status = false;
      });
    },
  },
  watch: {
    selected: function () {
      this.SelectedCount = 0;
      this.selected.forEach((itm) => {
        this.SelectedCount += parseFloat(itm.quantity);
      });
    },
  },
};
</script>

<style>
.v-dialog {
  overflow-y: hidden !important;
}
</style>
